<!-- =========================================================================================
  File Name: FullPage.vue
  Description: Full page layout
  ----------------------------------------------------------------------------------------
  Item Name: RIM-Nat / Plateforme 3D collaborative
  Author: Loïc ROYBON
  Author URL:
========================================================================================== -->


<template>
    <div class="h-full flex w-full no-gutter items-center justify-center">
        <div class="vx-col w-full my-8 px-4 sm:px-8 md:px-8 lg:px-12 xl:px-20">
            <div class="vx-row no-gutter h-full w-full">
                <div class="vx-col w-full">
                  <template>
                    <div class="relative">
                      <div class="vx-navbar-wrapper">
                        <vs-navbar class="vx-navbar navbar-custom navbar-skelton">
                          <router-link tag="div" to="/" class="vx-logo cursor-pointer mx-auto flex items-center">
                            <logo class="w-10 mr-4 fill-current text-primary" />
                            <span class="hidden sm:block vx-logo-text text-primary">RIM-Nat</span>
                          </router-link>
                          <vs-spacer />
                          <i18n />
                          <profile-drop-down />
                        </vs-navbar>
                      </div>
                    </div>
                  </template>

                </div>
                <div class="vx-col w-full my-10">
                  <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TheNavbarVertical    from '@/layouts/components/navbar/TheNavbarVertical.vue'
import I18n                 from '@/layouts/components/navbar/components/I18n.vue'
import ProfileDropDown      from '@/layouts/components/navbar/components/ProfileDropDown.vue'
import Logo                 from '@/layouts/components/Logo.vue'

export default {
  components: {
    TheNavbarVertical,
    I18n,
    ProfileDropDown,
    Logo
  }
}
</script>